.navbar-brand {
    text-align: left !important;
    img {
        max-width: 100px;
    }
}

.footer-logo {
    display: flex;
    text-align: left;
    flex-flow: column;
    img {
        max-width: 100px;
    }
}

.annual {
    &-box {
        display: block;
        margin: 3rem 0;
    }
    &-report {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 1rem;
        margin-bottom: 1rem;
        border-bottom: 1px solid #f3f3f3;
        h4 {
            font-size: 1.25rem;
            font-weight: 600;
            @media screen,
            (max-width: 640px) {
                font-size: 1rem;
            }
        }
    }
}